import React from 'react';

interface FeatureBoxProps {
  image: string;
  title: string;
  subtitle: string;
}

const FeatureBox: React.FC<FeatureBoxProps> = ({ image, title, subtitle }) => (
    <div className="shadow-lg p-6 rounded-lg bg-white flex flex-col items-center">
        <img src={image} alt="task_breakdown" className="md:block w-24 h-20 object-contain" />
        <h3 className="text-lg text-center font-semibold pt-6 text-dark">{title}</h3>
        <p className="text-grayish text-center mt-2 mx-2">{subtitle}</p>
    </div>
);

export default FeatureBox;