import React from 'react';
import Header from './components/Header';
import Section from './components/Section';
import FeatureBox from './components/FeatureBox';
import CallToAction from './components/CallToAction';
import HowItWorks from './components/HowItWorks';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <main>
        <div className="flex items-center">
          <div className="container flex flex-col mx-auto md:pr-14 lg:pr-8 pl-4 lg:pl-28">
            <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark pb-2">
              <span className="line-through">Missed</span>
              <span className="text-primary"> Meet</span> deadlines.<br />
            </h1>
            <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark pt-2">
            <span className="line-through">Unwanted</span>
            <span className="text-primary"> Manage</span> scope creep.<br />
            </h1>
            <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark pt-4">
              <span className="line-through">Inefficient</span>
              <span className="text-primary"> Seamless</span> communication.
            </h1>
            <h2 className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-2xl font-medium text-mediumDark pt-2 sm:pt-4 md:pt-6 lg:pt-8 xl:pt-10">
              Streamline tasks, track progress, and achieve your goals with our innovative project management tool.
            </h2>
          </div>
          <div className="flex-shrink ml-10">
            <img src="./first_section.png" alt="Topigon dashboard" className="hidden md:block" />
          </div>

        </div>

        <Section title="Discover Topigon" subtitle="Topigon revolutionizes the way you manage projects by allowing seamless collaboration between project owners and engineers. Easily break down tasks, estimate durations, and track progress — all in one intuitive platform." bgColor="bg-grayTint" shouldApplyBg={true}>
          <div className="grid md:grid-cols-3 gap-8 md:16">
            <FeatureBox image="./task_breakdown.png" title="Task Breakdown and Estimation" subtitle="Engineers can detail tasks and provide time estimates for accurate planning." />
            <FeatureBox image="./dynamic_timelines.png" title="Dynamic Project Timelines" subtitle="Automatically generate project timelines based on task estimates and dependencies." />
            <FeatureBox image="./real_progress.png" title="Real-time Progress Tracking" subtitle="Monitor task completion and project milestones with up-to-date progress indicators." />
          </div>
        </Section>

        <Section title="Your problems. Our solution." subtitle="" shouldApplyBg={false} alignment="text-center">
          <HowItWorks title="Inefficient communication." subtitle="Enhance project clarity with milestone-driven task management." imagePath="./how_it_works_section_1.png" alt="inefficient_milestones" />
          <HowItWorks title="Avoid surprises. Meet deadlines." subtitle="Proactively anticipate delays by monitoring projects with unprecedented precision." imagePath="./how_it_works_section_2.png" alt="assign_tasks" isNaturalDirection={false} />
          <HowItWorks title="Track scope creep." subtitle="Gain real-time insights on project delays, empowering proactive adjustments to meet deadlines and communicate effectively with stakeholders." alt="track_progress" imagePath="./how_it_works_track_progress.png" />
        </Section>

        <CallToAction />
      </main>
    </div>
  );
}

export default App;
