import React from 'react';
import { useForm } from '@formspree/react';

const ContactForm: React.FC = () => {
    const [state, handleSubmit] = useForm("xleqyrpd");
    if (state.succeeded) {
        return <div className="container mx-auto px-4 text-center py-8">
            <img src="./minion-muah.gif" alt="Minion" className="mx-auto" />
        </div>
    }

    return (
        <div className="max-w-sm mx-auto mt-8 p-4">
            <form className="mt-1 md:mt-8" onSubmit={handleSubmit}>
                <div className="text-left">
                    <label htmlFor="email" className="block text-grayish font-regular mb-2 text-sm md:text-base">
                        Email address</label>
                </div>

                <input
                    id="email"
                    type="email"
                    name="email"
                    className="w-full px-4 py-2 border rounded-lg shadow-sm focus:outline-none focus:ring mb-4 text-mediumDark"
                />

                <div className="pt-4">
                    <button className="w-full bg-primary text-white font-semibold py-2 rounded-lg hover:bg-blue-600 text-sm h-10" disabled={state.submitting}>Sign Up</button>
                </div>

                <p className="mt-4 text-left font-regular text-xs text-fadedGray">Want to talk to us? <a href="mailto:hestapp@gmail.com" className="text-primary text-xs font-semibold">Contact Us</a>
                </p>
            </form>
        </div>
    );
}

export default ContactForm;
