import React from 'react';

interface SectionProps {
    title: string;
    subtitle: string;
    children: React.ReactNode;
    bgColor?: string;
    shouldApplyBg: boolean;
    alignment?: 'text-left' | 'text-center' | 'text-right' | 'text-justify';
}

const Section: React.FC<SectionProps> = ({ title, subtitle, children, bgColor, shouldApplyBg, alignment }) => (
    <section className={`${shouldApplyBg ? "py-16" : "py-0"}`}>
        <div className={`${bgColor} ${shouldApplyBg ? "py-20" : "py-0"} ${alignment}`}>
            <div className="container flex flex-grow flex-col mx-auto px-4">
                <h2 className="text-2xl font-bold flex-full text-dark">{title}</h2>
                <p className="text-lightDark mt-4 text-base sm:text-md md:text-lg lg:text-xl w-full lg:w-1/2">{subtitle}</p>
                <div className="mt-10 md:mt-16">{children}</div>
            </div>
        </div>
    </section>
);

export default Section;