import React from 'react';

interface HowItWorksProps {
    title: string;
    subtitle: string;
    imagePath: string;
    alt: string;
    isNaturalDirection?: boolean;
}

const HowItWorks: React.FC<HowItWorksProps> = ({ title, subtitle, imagePath, isNaturalDirection = true, alt }) => (
    <div className="flex flex-col-reverse sm:flex-col justify-between">
        <div className={`text-left items-center flex-col-reverse ${isNaturalDirection ? "sm:flex-row" : "sm:flex-row-reverse"} flex px-4 lg:px-16 justify-between w-full pb-12`}>
            <div className="w-full sm:w-1/2 sm:px-6 lg:px-16 py-4 sm:py-16">
                <h3 className="text-lg xl:text-xl font-semibold text-dark mt-4">{title}</h3>
                <p className="text-grayish lg:text-lg">{subtitle}</p>
            </div>
            <img src={imagePath} alt={alt} className="w-full sm:w-1/2" />
        </div>
    </div>
);

export default HowItWorks;