import React from 'react';

const Header: React.FC = () => (
    <header className="bg-white py-4">
        <div className="container mx-auto px-4 flex justify-between items-center">
            <img src="./logo-icon/logo-196.png" alt="Topigon" className="h-16" />
        </div>
    </header>
);

export default Header;
