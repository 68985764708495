import React from 'react';
import ContactForm from './ContactForm';

const CallToAction: React.FC = () => (
  <section className="py-8 md:py-12 lg:py-16 text-center text-dark">
    <div className="container mx-auto px-4 md:px-16 lg:px-48 xl:52">
      <h1 className="text-3xl sm:text-4xl md:text-3xl lg:text-4xl xl:text-5xl font-bold text-dark"><span className="text-primary">Be the first</span> to experience Topigon</h1>
      <p className="text-base sm:text-lg md:text-xl lg:text-2xl xl:text-2xl font-regular text-mediumDark pt-2 sm:pt-4 md:pt-6 lg:pt-8">Sign up now to be notified when our product launches and gain early access to our project management tool.</p>
      
      <ContactForm />
    </div>
  </section>
);

export default CallToAction;